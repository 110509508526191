import logo from "../../assets/logos/logo.png";

export const language = "fr";

export const minimumOrder = 10;
export const maxCountPerItemInCart = 12;

export const dataVersionPostfix = `${24}`;
export const dataValidityMs = 86400e3; // one day

export const defaultPrepaymentAmount = 5;

export const thresholdStockTiers1 = 4;
export const thresholdStockTiers2 = 3;
export const thresholdStockTiers3 = 2;

export const enableNoindex = window.location.hostname !== "www.gifi.fr";

export const uaId =
  ["www.gifi-clickandcollect.fr", "www.gifi.fr"].indexOf(
    window.location.hostname
  ) >= 0
    ? "UA-181627617-1"
    : "UA-181627617-2";
export const awId =
  ["www.gifi-clickandcollect.fr", "www.gifi.fr"].indexOf(
    window.location.hostname
  ) >= 0
    ? "AW-403285723"
    : "AW-659493196";
export const awConversionId =
  ["www.gifi-clickandcollect.fr", "www.gifi.fr"].indexOf(
    window.location.hostname
  ) >= 0
    ? "AW-403285723/8Q4HCNiW1vsBENvNpsAB"
    : "AW-659493196/sWpyCMrf7fcBEMyivLoC";

export const getRedirectTo = () =>
  window.location.hostname === "www.gifi-clickandcollect.fr"
    ? `https://www.gifi.fr${window.location.pathname}${window.location.search}${window.location.hash}`
    : null;

export const isRecette =
  ["recette.gifi.mobsuccess.com"].indexOf(window.location.hostname) >= 0;

export const isPreprod =
  ["preprod.gifi.mobsuccess.com", "preprod.drive.live.mobsuccess.com"].indexOf(
    window.location.hostname
  ) >= 0;

export const isDev =
  process.env.NODE_ENV === "development" ||
  [
    "livraison.gifi.fr",
    "preprod.gifi.fr",
    "www.gifi.fr",
    "www.gifi-clickandcollect.fr",
    "www.mobsuccess.com",
    "dashboard.gifi-clickandcollect.fr",
  ].indexOf(window.location.hostname) === -1;

const reactAppApi = process.env.REACT_APP_API;

export function getApiUrls() {
  if (!reactAppApi) {
    throw new Error("Missing environment variable REACT_APP_API");
  }
  return {
    PlaceOrder: `${reactAppApi}/PlaceOrder`,
    GetOrder: `${reactAppApi}/GetOrder`,
    Orders: `${reactAppApi}/Orders`,
  };
}

export const environment = isPreprod
  ? "preprod"
  : isRecette
  ? "recette"
  : isDev
  ? "development"
  : "production";

export const maxNumberOfimagesInProduct = 5;

export const backendHost =
  window.top.location.hostname === "www.gifi.fr"
    ? ""
    : window.top.location.hostname === "preprod.drive.live.mobsuccess.com"
    ? "https://preprod.gifi.mobsuccess.com"
    : isPreprod || isRecette
    ? ""
    : process.env.NODE_ENV === "development" || process.env.REACT_APP_AMPLIFY
    ? "https://dev.gifi.mobsuccess.com"
    : "";

export function formatStoreId(storeId) {
  let s = (storeId || "").toString();

  // Keep only numbers in storeId
  s = s.replace(/\D/g, "");

  while (s.length < "0000010106".length) {
    s = `0${s}`;
  }
  return s;
}

export const validEmailRegExp =
  /^[\w.\-_']+(?:\+[\w\-_.]+)?@[\w.\-_']+\.[a-zA-Z]{2,15}$/;

export const getCCSiteUriForStoreId = (storeId) =>
  isPreprod
    ? `https://preprod.gifi.mobsuccess.com/${
        storeId ? `?storeId=${storeId}` : ""
      }`
    : isRecette
    ? `https://recette.gifi.mobsuccess.com/${
        storeId ? `?storeId=${storeId}` : ""
      }`
    : isDev
    ? `https://dev.gifi.mobsuccess.com/${storeId ? `?storeId=${storeId}` : ""}`
    : `https://www.gifi.fr/${storeId ? `?storeId=${storeId}` : ""}`;

export function hookOnCloseOverlay() {
  if (document.referrer.match(/gifi\.zone-secure\.net/)) {
    window.close();
  }
}

export const defaultStoreId = "00000";

export function getProductImageSrcJpg(imageSrc) {
  return imageSrc
    .replace(
      /gifi.fr\/media\/catalog\/product\/\d+\/\d+\//,
      "gifi.fr/images/product/"
    )
    .replace("livraison.gifi.fr", "www.gifi.fr");
}

export const isProductId = (s) => s.toString().match(/^[0-9]{6}$/);

export const clientLogo = logo;

export const enableAutoplay = (() => {
  const params = new URLSearchParams(window.location.search);
  return !params.has("autoplay") || Number(params.get("autoplay"));
})();

export const isInScreenshotMode = (() => {
  const params = new URLSearchParams(window.location.search);
  return params.has("screenshot-mode");
})();

export const enableMarketplace = false;
export const enableKbis = false;

export const messageCodeExcluded = "000";
export const messageCodeOutOfStock = "001";
export const messageCodeLimitedStockStoreUnknow = "002";
export const messageCodeAvailable = "003";
export const messageCodeLimitedStockStoreAvailable = "004";
export const messageCodeOutOfStockUntilDate = "005";
